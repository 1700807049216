<template>
  <v-card
    :outlined="smallComponent ? false : true"
    :flat="smallComponent ? true : false"
  >
    <v-card-title
      :class="
        smallComponent
          ? 'pa-4 font-weight-bold '
          : 'pa-4 font-weight-bold accent'
      "
    >
      {{ this.title }}
      <v-spacer></v-spacer>

      <v-btn
        align="center"
        color="secondary"
        :small="smallComponent ? true : false"
        class="white--text"
        :disabled="disabled"
        @click="onButtonClick"
      >
        Adicionar
        <input
          multiple
          type="file"
          accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
          class="d-none"
          ref="uploader"
          @change="createFiles"
        />
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text :class="smallComponent ? 'pa-0' : ''">
      <div
        v-if="files.length > 0"
        style="height: calc(100vh - 290px); overflow-x: hidden"
      >
        <v-card flat v-for="file in files" :key="file.id">
          <div class="d-flex align-center justify-center">
            <v-row class="align-center justify-center">
              <v-col cols="12" md="2">
                <v-card flat max-width="100px" class="mx-auto my-3">
                  <v-img
                    :src="file.url"
                    max-height="125px"
                    cover
                    v-if="isImage(file.tipo)"
                  >
                  </v-img>
                  <v-icon v-if="file.tipo === 'pdf'" size="100">
                    mdi-file-pdf-box
                  </v-icon>
                  <v-icon
                    v-if="file.tipo === 'xlsx' || file.tipo === 'xls'"
                    size="100"
                  >
                    mdi-file-excel
                  </v-icon>
                  <v-icon
                    v-if="
                      file.tipo === 'txt' ||
                      file.tipo === 'odt' ||
                      file.tipo === 'doc' ||
                      file.tipo === 'docx'
                    "
                    size="100"
                  >
                    mdi-file-word
                  </v-icon>
                  <v-icon
                    v-if="file.tipo === '/mp4' || file.tipo === '/mp3'"
                    size="30"
                  >
                    mdi-file-video-outline
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card-text>
                  <v-card class="my-2" flat>Nome: {{ file.nome }}</v-card>
                  <v-spacer></v-spacer>
                  <v-card class="my-2" flat>Usuario: {{ file.criador }}</v-card>
                  <v-spacer></v-spacer>
                  <v-card class="my-2" flat
                    >Data:
                    {{ file.created_at | dateFormat("dd/MM/yyyy") }}</v-card
                  >
                </v-card-text>
              </v-col>
              <v-col cols="12" md="1">
                <div class="mt-4">
                  <v-btn
                    v-if="isImage(file.tipo)"
                    icon
                    small
                    @click.stop="goToImagem(file)"
                  >
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!isImage(file.tipo)"
                    icon
                    small
                    @click.stop="
                      file.tipo === 'pdf'
                        ? goToPdf(file.url)
                        : openFull(file.url)
                    "
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                  <v-btn
                    class="d-flex align-end mt-10"
                    :disabled="disabled"
                    icon
                    color="error"
                    small
                    @click.stop="delFile(file.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
        </v-card>
      </div>
      <div v-else>
        <span> Nenhum arquivo adicionado! </span>
      </div>
      <v-card-text class="d-flex pa-0 ma-0 text-subtitle-2">
        <div>
          Formatos permetidos : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc,
          .docx, .odt
        </div>
        <v-spacer></v-spacer>
        <div>Max 100MB</div>
      </v-card-text>
    </v-card-text>

    <v-dialog
      v-if="dialogImage"
      v-model="dialogImage"
      @click:outside="dialogImage = false"
      max-height="80vh"
      max-width="1000px"
    >
      <v-card>
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-4">
              <v-card flat>
                <v-img :src="fullScreenFile.url" max-height="80vh" contain>
                </v-img>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="accent"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-left: 1px solid rgba(0, 0, 0, 0.12) !important;'
                  : ''
              "
            >
              <v-card-title class="text-subtitle-1 pa-3">
                {{ fullScreenFile.nome }}
                <v-spacer></v-spacer>
                <v-btn icon :href="fullScreenFile.url" target="_blank">
                  <v-icon color="green">mdi-file-download</v-icon>
                </v-btn>
                <v-btn icon @click="dialogImage = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="fullScreenFile">
                <div>Origem</div>
                <v-sheet class="pa-2">
                  {{ fullScreenFile.origem }}
                </v-sheet>
                <div class="mt-4">Path</div>
                <v-sheet class="pa-2">
                  {{ fullScreenFile.path }}
                </v-sheet>
                <div class="mt-4">Data</div>
                <v-sheet class="pa-2" v-if="fullScreenFile.created_at">
                  {{ fullScreenFile.created_at | dateFormat("dd/MM/yyyy") }}
                </v-sheet>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="PDF"
    />

    <v-dialog
      v-if="editFileDialog"
      v-model="editFileDialog"
      @click:outside="$emit('update:editFileDialog', false)"
      @keydown.esc="$emit('update:editFileDialog', false)"
      scrollable
      max-width="400px"
      width="100%"
    >
      <v-card>
        <v-card-title class="accent text-h5 font-weight-bold pa-3">
          Editar nome do arquivo
          <v-spacer></v-spacer>
          <v-btn icon @click="editFileDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-6">
          <v-text-field v-model="textName" label="Nome"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-btn @click="editFileDialog = false"> voltar </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="secondary" class="ml-3" @click="updateFile"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { saveAs } from "file-saver";
import {
  filterFiles,
  deleteMidia,
  uploadFiles,
  putFile,
} from "@/api/geral/midias.js";
export default {
  name: "InternFileManager",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Midias",
    },
    origem: {
      type: String,
    },
    origem_id: {
      type: Number,
    },
    smallComponent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      textName: "",
      files: [],
      file: null,
      editFileDialog: false,
      fullScreenFile: null,

      dialogImage: false,
      pdfData: null,
      pdfDialog: false,
    };
  },

  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
  },

  computed: {},
  methods: {
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    downloadWithAxios(url, title) {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },

    async downloadImg(item) {
      let url = "https://ole.softpar.inf.br/" + item.path;
      // let url = item.url;
      await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, item.nome);
        });
      "downloading", url;
    },

    goToPdf(url) {
      this.pdfDialog = true;
      this.pdfData = url;
    },

    goToImagem(item) {
      this.fullScreenFile = item;
      this.dialogImage = true;
    },

    openFull(url) {
      window.open(url, "_blank");
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },

    updateFile() {
      if (this.textName.length) {
        this.loading = true;
        let file = {};
        file = { ...this.file };
        delete file.criador;
        file.nome = this.textName;

        putFile(file, file.id).then((response) => {
          if (response.status === 201) {
            this.$toast.success("Nome editado");
            this.getFiles();

            this.editFileDialog = false;
            this.loading = false;
          }
        });
      } else {
        this.$toast.error("Não permitido!!!");
      }
    },

    delFile(id) {
      this.$Progress.start();
      deleteMidia(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Arquivo excluido com exito!");
            this.getFiles();
            this.$Progress.finish();
          }
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },

    createFiles(event) {
      // if (this.$refs.form.validate()) {
      let block = false;
      const files = event.target.files;
  
      [...files].forEach((file) => {
        if (file.size >= 104857600) {
          this.$toast.error("O tamanho maximo do arquivo permitido é 100MB");
          block = true;
          return;
        } else if (
          file.type.substr(-5) != "/jpeg" &&
          file.type.substr(-4) != "/png" &&
          file.type.substr(-4) != "/txt" &&
          file.type.substr(-4) != "/xls" &&
          file.type.substr(-6) != ".sheet" &&
          file.type.substr(-4) != "/pdf" &&
          file.type.substr(-4) != "/doc" &&
          file.type.substr(-5) != ".text" &&
          file.type.substr(-9) != ".document" &&
          file.name.substr(-4) != ".doc" &&
          file.name.substr(-5) != ".docx"
        ) {
          this.$toast.error("Formato não permitido");
          block = true;
          return;
        }
      });

      if (block === false) {
        this.loading = true;
        this.file = event.target.files;
        const file = new FormData();
        file.append("origem", this.origem);
        file.append("origem_id", this.origem_id);

        if (this.file) {
          for (var i = 0; i < this.file.length; i++) {
            let files = this.file[i];
            file.append("files[" + i + "]", files);
          }
        }

        uploadFiles(file)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Midia adicionado com sucesso!");
              this.file = null;
              this.getFiles();
              this.loading = false;
            }
          })
          .finally(() => {
            this.loading = false;
            this.file = null;
          });
        // }
      } else {
        this.file = null;
        this.loading = false;
      }
    },

    getFiles() {
      this.loading = true;
      this.$Progress.start();
      if (this.origem && this.origem_id) {
        return filterFiles(`?origem=${this.origem}&origem_id=${this.origem_id}`)
          .then((response) => {
            this.files = response;
            this.loading = false;
            this.$Progress.finish();
          })
          .catch(() => {
            this.loading = false;
            this.$Progress.fail();
          });
      }
    },
    isImage(tipo) {
      if (
        tipo === "png" ||
        tipo === "jpg" ||
        tipo === "jpeg" ||
        tipo === "img"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  async mounted() {
    await this.getFiles();
  },
};
</script>

<style lang="scss"></style>
